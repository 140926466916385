// extracted by mini-css-extract-plugin
export var boldBreak = "SectionHeader-module--boldBreak--c6459";
export var center = "SectionHeader-module--center--88b8a";
export var large = "SectionHeader-module--large--12d58";
export var left = "SectionHeader-module--left--727f4";
export var med = "SectionHeader-module--med--0bb18";
export var navy = "SectionHeader-module--navy--847ee";
export var right = "SectionHeader-module--right--102d6";
export var small = "SectionHeader-module--small--8679c";
export var tag = "SectionHeader-module--tag--cc765";
export var trim = "SectionHeader-module--trim--f4ac9";
export var trimSpaceing_large = "SectionHeader-module--trimSpaceing_large--0120c";
export var white = "SectionHeader-module--white--408b4";
export var xsmall = "SectionHeader-module--xsmall--27a40";