import React from 'react'
import * as styles from './Containers.module.scss'

const SplitTwoToneContainer = ({id, children, classes=null}) => {
    // color: navy, dark navy, white, gray
    //type: fullWidth, contained
    //children must contain two direct children only
    return(
        <article id={id} className={`${styles.twoToneContainer} ${classes ? classes.join(' ') : ''}`}>
            {children}
        </article>
    )
}

export default SplitTwoToneContainer 