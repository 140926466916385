import React from "react"
import * as styles from './CultureTree.module.scss'
import TreeNode from "./TreeNode/TreeNode"


const CultureTree = () => {
    return(  
        <article id={'cultureTree'} className={`${styles.cultureTree}`}>
            <h2 className={styles.upperTitle}>We Put <span>People First</span></h2>
            <section className={`${styles.treeNodes}`}>
                <TreeNode>Invest in our employees; education &amp; training</TreeNode>
                <TreeNode position='right'>Accept &amp; appreciate diversity</TreeNode>
                <TreeNode>Empower our employees</TreeNode>
                <TreeNode position='right'>Exceed our customers’ expectations</TreeNode>
                <TreeNode>Anticipate our customers needs</TreeNode>
                <TreeNode position='right'>Ensure we offer value to our customers</TreeNode>
                <TreeNode>Understand our customers top issues</TreeNode>
                <TreeNode position='right'>Support our customers achievement of their goals</TreeNode>
            </section>
            <h2 className={styles.lowerTitle}>Delight <span>Our Customers</span></h2>
        </article>
    )
}

export default CultureTree