import React from 'react'

import GeneralPageLayout from '../components/Layouts/GeneralPage'
import OutlineButton from '../components/UI/Buttons/OutlineButton/OutlineButton'
import SplitTwoToneContainer from '../components/UI/Containers/SplitTwoToneContainer'
import CultureTree from '../components/UI/Data/CultureTree/CultureTree'
import MainTextBlock from '../components/UI/MainTextBlock/MainTextBlock'
import CapabilityVideo from './../components/UI/Capabilities/CapabilityVideo/CapabilityVideo'
import SectionContainer from './../components/UI/Containers/SectionContainer'
import SectionHeader from './../components/UI/SectionHeader/SectionHeader'
import './../styles/pageSpecific/ourCulture.scss'


const OurCulturePage = () => {
    const pageInfo = {
        pageType:'interior',
        title1: 'Our',
        title2: 'Culture',
        breadcrumbs: ['Who We Are'],
        ctaButton: false,
        ctaButtonData:{
            text: '',
            url: ''
          }
    } 
 
    return(
        <GeneralPageLayout pageData={pageInfo}>
           <div className="content">
 
               <SectionContainer id='introCulture' color='white' type='fullWidth' classes={['inlineChildren_mobile', 'equalChildren', 'alignChildrenCenter']}>
                    <section>
                        <SectionHeader color='navy' alignment='left' size='med' trim='true'>Commited <span>To Serving</span></SectionHeader>
                        <OutlineButton buttonData={{text:'More On How Ethics Is Our Priority', url:'/ethics-sustainability', type:'colored'}} type='colored'/>
                    </section>
                    <section>
                        <MainTextBlock>
                        <p>In today's rapidly advancing technological environment, we understand and are committed to providing innovative solutions to our customers to ensure warfighter readiness. We pride ourself on exceptional service to our people, customers and country.</p>
                        </MainTextBlock>                        
                    </section>
                </SectionContainer>

               {/* Mission & Values */}
               <SplitTwoToneContainer id='mission'>
                   <section>
                        <div> 
                            <h3>Our <span>Mission</span></h3>
                            <p>Empower and invest in people, to do the work that matters the most, better than anyone else.</p>
                        </div>
                   </section>
                   <section>
                        <div>
                            <h3>Our <span>Vision</span></h3>
                            <p>Be the driving force for the success of our nation's best—through unparalleled capabilities, commitment, and culture.</p>
                        </div>
                   </section>
               </SplitTwoToneContainer>

               {/* Culture Tree */}
               <SectionContainer id='tree' color='white' type='contained'>
                    <CultureTree />
               </SectionContainer>

               {/* Appriciation Video */}
               <SectionContainer id='serviceVideo' color='navy' type='pressedEdgeRight' classes={['inlineChildren_half', 'capabilitySection']}>
                   <section>
                        <SectionHeader color='white' alignment='left' size='med' boldBreak={true}>Appreciation of <span>Our Employees</span></SectionHeader>
                        <MainTextBlock color='white'>
                            <p>At Precise, we recognize and invest in employees whose exceptional talents, commitment and integrity shape a work environment that supports our mission and vision. We recognize our competitive advantage is, and always will be, our people.</p>
                        </MainTextBlock>     
                   </section>
                   <CapabilityVideo url='https://www.youtube.com/embed/BJiI6AqTYc8?start=2' background='dark' title='Precise Employee Appreciation '/>
               </SectionContainer>

               {/* Efforts Callout */}
               <SectionContainer id='efforts' color='gray' type='fullWidth' classes={['inlineChildren', 'narrowContainer']}>
                    <SectionHeader color='navy' alignment='left' size='small' trim={true}>Our Sustainability <span>Efforts</span></SectionHeader>
                    <OutlineButton 
                        buttonData={
                            {
                                text: 'Explore How We’re Making a Difference',
                                url: '/ethics-sustainability#sustaibilityStatement',
                                icon:''
                            }}
                        type='colored'/>
               </SectionContainer>

             
            </div> 
        </GeneralPageLayout>
    )
}

export default OurCulturePage