// extracted by mini-css-extract-plugin
export var blue = "SolidHexagon-module--blue--8024a";
export var center = "SolidHexagon-module--center--6ff0d";
export var gray = "SolidHexagon-module--gray--f8aae";
export var hexagonBG = "SolidHexagon-module--hexagonBG--cecd9";
export var lightGray = "SolidHexagon-module--lightGray--17d9d";
export var lightGrayButton = "SolidHexagon-module--lightGrayButton--f7246";
export var red = "SolidHexagon-module--red--e400e";
export var shadow = "SolidHexagon-module--shadow--55a7b";
export var small = "SolidHexagon-module--small--7efe5";
export var white = "SolidHexagon-module--white--8fd67";