import React from 'react'
import * as styles from './SolidHexagon.module.scss'

const SolidHexagon = ({
  color = 'gray',
  size = 'med',
  shadow = false,
  position = 'left',
  children,
}) => {
  return (
    <div
      className={`${styles.hexagonBG} ${styles[`${size}`]} ${
        styles[`${position}`]
      } ${styles[`${color}`]} ${shadow ? styles.shadow : ''}`}
    >
      {children}
    </div>
  )
}

export default SolidHexagon
