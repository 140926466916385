import React from "react"
import * as styles from './TreeNode.module.scss'
import SolidHexagon from './../../../Hexagon/SolidHexagon/SolidHexagon'


const TreeNode = ({ position='left', children }) => {
    return(  
        <section className={`${styles.treeNode} ${styles[`${position}`]}`}>
            {children}
            <div className={styles.hexagonWrap}>
                <SolidHexagon color='white' />
                <SolidHexagon color='lightGray' />
            </div>
            <div className={`hexagon red ${styles.hexagonRedMarker}`}>
                <div className="hexTop"></div>
                <div className="hexBottom"></div>
            </div>
        </section>
    )
}

export default TreeNode