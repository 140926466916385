import React from 'react'
import * as styles from './SectionHeader.module.scss'

const SectionHeader = ({
  children,
  color,
  size,
  alignment,
  trim = false,
  trimSpacing = null,
  tag = null,
  boldBreak = false,
  classes = [],
}) => {
  return (
    <section
      className={`
            ${styles[`${color}`]} 
            ${styles[`${alignment}`]} ${classes.join(' ')}`}
    >
      <h2
        className={`
                ${styles[`${size}`]} 
                ${trim ? styles.trim : ''}
                ${trimSpacing ? styles[`trimSpaceing_${trimSpacing}`] : ''}
                ${boldBreak ? styles.boldBreak : ''}`}
      >
        {children}
      </h2>
      {tag && <p className={styles.tag}>{tag}</p>}
    </section>
  )
}

export default SectionHeader
